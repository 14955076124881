// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {h2IQBCaVN: {hover: true}};

const cycleOrder = ["h2IQBCaVN"];

const serializationHash = "framer-b5OHr"

const variantClassNames = {h2IQBCaVN: "framer-v-iywak6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, FvoIIwJWi: title ?? props.FvoIIwJWi ?? "Grab Lifetime Deal"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, FvoIIwJWi, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "h2IQBCaVN", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-iywak6", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"h2IQBCaVN"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"h2IQBCaVN-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO1BvbHlTYW5zIFRyaWFsIE1lZGlhbg==", "--framer-font-family": "\"PolySans Trial Median\", \"PolySans Trial Median Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-text-decoration": "underline"}}>Grab Lifetime Deal</motion.p></React.Fragment>} className={"framer-1ou301o"} fonts={["CUSTOM;PolySans Trial Median"]} layoutDependency={layoutDependency} layoutId={"CjtVfHYbW"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={FvoIIwJWi} verticalAlignment={"top"} withExternalLayout/><SVG className={"framer-nk2yz5"} data-framer-name={"icon_2_"} fill={"black"} intrinsicHeight={24} intrinsicWidth={24} layoutDependency={layoutDependency} layoutId={"bU11VXsNO"} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" fill=\"none\" stroke=\"#000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" style=\"width:100%;cursor:auto;height:100%\"><path d=\"M7 17 17 7M7 7h10v10\"/></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-b5OHr.framer-1nteh2b, .framer-b5OHr .framer-1nteh2b { display: block; }", ".framer-b5OHr.framer-iywak6 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-b5OHr .framer-1ou301o { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-b5OHr .framer-nk2yz5 { aspect-ratio: 1 / 1; flex: none; height: 24px; position: relative; width: var(--framer-aspect-ratio-supported, 24px); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-b5OHr.framer-iywak6 { gap: 0px; } .framer-b5OHr.framer-iywak6 > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-b5OHr.framer-iywak6 > :first-child { margin-left: 0px; } .framer-b5OHr.framer-iywak6 > :last-child { margin-right: 0px; } }", ".framer-b5OHr.framer-v-iywak6.hover.framer-iywak6 { gap: 12px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-b5OHr.framer-v-iywak6.hover.framer-iywak6 { gap: 0px; } .framer-b5OHr.framer-v-iywak6.hover.framer-iywak6 > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-b5OHr.framer-v-iywak6.hover.framer-iywak6 > :first-child { margin-left: 0px; } .framer-b5OHr.framer-v-iywak6.hover.framer-iywak6 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 150
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"SXMRDEB_0":{"layout":["auto","auto"]}}}
 * @framerVariables {"FvoIIwJWi":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerMSXq0OrMy: React.ComponentType<Props> = withCSS(Component, css, "framer-b5OHr") as typeof Component;
export default FramerMSXq0OrMy;

FramerMSXq0OrMy.displayName = "Micro button <div>";

FramerMSXq0OrMy.defaultProps = {height: 24, width: 150};

addPropertyControls(FramerMSXq0OrMy, {FvoIIwJWi: {defaultValue: "Grab Lifetime Deal", displayTextArea: true, title: "Title", type: ControlType.String}} as any)

addFonts(FramerMSXq0OrMy, [{explicitInter: true, fonts: [{family: "PolySans Trial Median", source: "custom", url: "https://framerusercontent.com/assets/rG5Hl6m4brC3DQUbPQ6H4OrVgE.otf"}]}], {supportsExplicitInterCodegen: true})